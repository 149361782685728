// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-layout {
    background-color: var(--color-bg-secondary);

    .main-layout__content {
        padding: var(--space-2xl);
    }
}`, "",{"version":3,"sources":["webpack://./client/1-app/layouts/main-layout/mainLayout.css"],"names":[],"mappings":"AAAA;IACI,2CAA2C;;IAE3C;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":[".main-layout {\n    background-color: var(--color-bg-secondary);\n\n    .main-layout__content {\n        padding: var(--space-2xl);\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

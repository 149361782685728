export default {
	translation: {
		header: {
			profile: 'Профиль',
			logout: 'Выйти',
			admin: 'Администрирование',
		},
		authPage: {
			signIn: 'Вход в систему',
			submit: 'Подтвердить',
			toSignUp: 'Зарегистрироваться',
			signUp: 'Регистрация',
			toSignUp: 'Зарегистрироваться',
			toSignUp: 'Зарегистрироваться',
			toSignIn: 'Есть аккаунт? Войти',
			username: 'Имя пользователя',
			password: 'Пароль',
			enterPassword: 'Введите пароль',
			email: 'Электронная почта',
			rememberMe: 'Запомнить меня на этом устройстве',
			LOGIN_FAILED: 'Неверный логин/пароль',
			LOGIN_WAIT: 'Превышен лимит попыток',
			USER_BLOCKED: 'Учетная запись заблокирована',
			NO_SESSION_CAPTCHA: 'Не сгенерирован новый код',
			EMPTY_CAPTCHA: 'Не введен код',
			INVALID_CAPTCHA: 'Неверный код',
			AUTHORIZATION_REQUIRED: 'Необходимо авторизоваться',
			EMAIL_NOT_FOUND: 'Email не найден',
		},
		User: {
			plural: 'Пользователи',
			title: 'Пользователь',
			phone: 'Телефон',
			email: 'Email',
		},
		Role: {
			plural: 'Роли',
			name: 'Код',
			description: 'Описание',
		},
		AdminPage: {
			title: 'Администрирование',
		},
		server: {
			error: 'Ошибка сервера',
		},
		Contragent: {
			plural: 'Контрагенты',
			title: 'Контрагент',
			name: 'Наименование',
			shortName: 'Короткое наименование',
			code: 'Код',
		},
		ContragentType: {
			plural: 'Типы контрагентов',
			title: 'Тип контрагента',
			name: 'Наименование',
			shortName: 'Короткое наименование',
			code: 'Код',
		},
		ContragentKind: {
			plural: 'Виды контрагентов',
			title: 'Вид контрагента',
			name: 'Наименование',
			shortName: 'Короткое наименование',
			code: 'Код',
		},
	},
};


import { User } from '@consta/uikit/User';
import { fio } from '@shared/lib/fio';
import { observer } from 'mobx-react';

export const UserAvatar = observer(({ user, onClick, showName = true, ref, info, size }) => {
	const style = {
		cursor: onClick ? 'pointer' : 'default',
	};

	const title = showName ? fio(user) : undefined;
	const url = user.avatar ? user.downloadFile('avatar') + `?image=[{"resize":{"width":100,"height":100}}]&` + user.avatar : null;

	return (
		<div className='UserAvatar' onClick={onClick} style={style} ref={ref}>
			<User name={title} avatarUrl={url} info={info} size={size} />
		</div>
	);
});

import store from '@appStore';
import { observer } from 'mobx-react';
import Logo from './assets/logo.svg?url';
import LogoText from './assets/logoText.svg';
import { classnames } from '@bem-react/classnames';
import './logoLabel.css';
import { Picture } from '@consta/uikit/Picture';

export const LogoLabel = observer(({ className, onClick, noLabel = false }) => {
	const isDefaultTheme = store.local.theme === 'default';
	const style = {};
	if (onClick) {
		style.cursor = 'pointer';
	}

	className = classnames('LogoLabel', className, !isDefaultTheme ? 'darkmode' : undefined);

	return (
		<div className={className} onClick={onClick} style={style}>
			<Picture src={Logo} />
			{!noLabel && <LogoText className='text' />}
		</div>
	);
});

import { presetGpnDisplay, presetGpnDefault } from '@consta/uikit/Theme';
import './styles/dark.css';
import './styles/default.css';

export const THEMES = {
	DEFAULT: {
		...presetGpnDefault,
	},
	DARK: {
		...presetGpnDisplay,
	},
};


import { useState } from 'react';

/** Хук для инициализации стора в функциональном компоненте. Сработает лишь при первом рендере
 * Пример использования  useStore(() => new Store(props))
 */
export const useStore = (Store) => {
	const [store] = useState(Store);
	return store;
};


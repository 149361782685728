import { observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';

class TestPageStore {
	@observable count2 = 0;
}

@observer
export class TestPage extends React.Component {
	constructor(props) {
		super(props);
		this.store = new TestPageStore();
	}
	@observable count = 0;

	render() {
		return (
			<div>
				{this.count}
				<button onClick={() => this.count++}>inc</button>
				{this.store.count2}
				<button onClick={() => this.store.count2++}>inc</button>
			</div>
		);
	}
}

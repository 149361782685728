import { Layout as HeaderLayout } from '@consta/header/Layout';
import { UserAvatar } from '@shared/ui/user-avatar';
import { ContextMenu } from '@consta/uikit/ContextMenu';
import { Button } from '@consta/uikit/Button';
import store from '@appStore';
import './header.css';
import { observer } from 'mobx-react';
import { HeaderStore } from './store';
import { useStore } from '@shared/hooks';
import { useRef } from 'react';
import IconChatTeardrop from './assets/chatTeardrop.svg';
import IconBell from './assets/bell.svg';
import { Notifications } from '@consta/header/Notifications';

export const Header = observer(() => {
	const { isUserContextOpen, onUserContextSelect, userContextItems, openUserContext, closeUserContext } = useStore(() => new HeaderStore());
	const ref = useRef(null);
	return (
		<HeaderLayout
			className='Header'
			rowCenter={{
				right: (
					<div className='right'>
						<div className='actions'>
							<Button view='clear' onlyIcon iconRight={IconChatTeardrop} />
							<Button view='clear' onlyIcon iconRight={IconBell} />
						</div>

						<UserAvatar ref={ref} user={store.model.user} onClick={openUserContext} info='Инсталлятор' />
						<ContextMenu
							isOpen={isUserContextOpen}
							items={userContextItems}
							getItemStatus={(item) => item.accent}
							anchorRef={ref}
							direction='downStartRight'
							onClickOutside={closeUserContext}
							getItemOnClick={onUserContextSelect}
						/>
					</div>
				),
			}}
		/>
	);
});

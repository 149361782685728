import { NavLink, useLocation } from 'react-router-dom';
import store from '@appStore';
import { LogoLabel } from '@features/logo-label';
import { ThemeSwitcher } from '@features/theme-switcher';
import { Layout } from '@consta/uikit/Layout';
import { Text } from '@consta/uikit/Text';
import { ArrowSwitcher } from '@features/arrow-switcher';
import { observer } from 'mobx-react';
import { classnames } from '@bem-react/classnames';
import { SIDEBAR_MENU } from './menu';
import { Navbar, NavbarRail } from '@consta/header/Navbar';
import './sideBar.css';

export const SideBar = observer(() => {
	const onToggleSidebarMode = () => (store.local.fullSidebar = !store.local.fullSidebar);
	const fullMode = store.local.fullSidebar;
	const sidebarClassName = classnames('sidebar', !fullMode ? 'short' : undefined);
	const goToRoot = () => store.router.navigate('/');
	const navBarItems = SIDEBAR_MENU.filter((item) => !item.access || item.access(store));
	const getItemLabel = (item) => item.name;
	const { pathname } = useLocation();

	const navBarProps = {
		items: navBarItems,
		onItemClick: (item) => store.router.navigate(item.path),
		getItemActive: (item) => {
			const isSamePath = item.path === pathname;
			return isSamePath || (item.path !== '/' && pathname.startsWith(item.path));
		},
	};

	return (
		<Layout className={sidebarClassName} flex={1} direction='column'>
			<ArrowSwitcher className='size-switcher' active={fullMode} onClick={onToggleSidebarMode} />
			<div className='logo-wrapper'>
				<LogoLabel className='logo' noLabel={!fullMode} onClick={goToRoot} />
			</div>

			<Layout className='content'>
				{fullMode ? <Navbar {...navBarProps} /> : <NavbarRail {...navBarProps} getItemTooltip={getItemLabel} getItemLabel={(item) => undefined} />}

				<div className='footer'>
					<ThemeSwitcher noLabel={!fullMode} />
					<div className='copyright'>
						<Text view='ghost' size='xs'>
							{VERSION} ({BUILD})
						</Text>
						{fullMode && (
							<Text view='ghost' size='xs'>
								© SmartUnit LLC {new Date().getFullYear()}
							</Text>
						)}
					</div>
				</div>
			</Layout>
		</Layout>
	);
});

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ArrowSwitcher {
    background-color: var(--color-bg-default);
    border: 1px solid var(--color-bg-stripe);

    svg {
        color: var(--color-typo-secondary);
    }
}`, "",{"version":3,"sources":["webpack://./client/4-features/arrow-switcher/arrowSwitcher.css"],"names":[],"mappings":"AAAA;IACI,yCAAyC;IACzC,wCAAwC;;IAExC;QACI,kCAAkC;IACtC;AACJ","sourcesContent":[".ArrowSwitcher {\n    background-color: var(--color-bg-default);\n    border: 1px solid var(--color-bg-stripe);\n\n    svg {\n        color: var(--color-typo-secondary);\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

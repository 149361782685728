import { Navigate, Outlet, useLocation } from 'react-router';
import store from '@appStore';

export const RequireAuth = () => {
	let location = useLocation();

	if (!store.model.isAuthorized) {
		return <Navigate to='/auth' state={{ from: location.pathname }} />;
	}

	return <Outlet />;
};

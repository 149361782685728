import { Button } from '@consta/uikit/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

export const ToRootButton = (props) => {
	const { t } = useTranslation('components', { keyPrefix: 'ToRootButton' });
	const navigate = useNavigate();
	const onClick = () => navigate('/');
	return <Button view='ghost' label={t('toMain')} onClick={onClick} {...props} />;
};

import { AppRouter } from './AppRouter';
import { SnackBar } from '@consta/uikit/SnackBar';
import store from '@appStore';
import './styles/reset.css';
import './styles/app.css';
import { Theme } from '@consta/uikit/Theme';
import { observer } from 'mobx-react';

export const App = observer(() => (
	<Theme preset={store.selectedThemePreset}>
		<AppRouter />
		<SnackBar
			// className={cnSnackBarExampleTimer('SnackBar')}
			form={'round'}
			items={store.notifications}
			// getItemAutoClose={getItemAutoClose}
			// getItemShowProgress={getItemShowProgress}
			onItemClose={(item) => dispatchItems({ type: 'remove', item })}
		/>
	</Theme>
));

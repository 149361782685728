// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header {
    .right {
        display: flex;
        align-items: center;

        .actions {
            display: flex;
            gap: var(--space-2xs);
        }

        .UserAvatar {
            margin-left: var(--space-m);
        }
    }
}`, "",{"version":3,"sources":["webpack://./client/3-widgets/header/header.css"],"names":[],"mappings":"AAAA;IACI;QACI,aAAa;QACb,mBAAmB;;QAEnB;YACI,aAAa;YACb,qBAAqB;QACzB;;QAEA;YACI,2BAA2B;QAC/B;IACJ;AACJ","sourcesContent":[".Header {\n    .right {\n        display: flex;\n        align-items: center;\n\n        .actions {\n            display: flex;\n            gap: var(--space-2xs);\n        }\n\n        .UserAvatar {\n            margin-left: var(--space-m);\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

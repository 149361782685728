import './mainLayout.css';
import { Layout } from '@consta/uikit/Layout';
import { Header } from '@widgets/header';
import { SideBar } from '@widgets/sidebar';
import { Outlet } from 'react-router';
import store from '@appStore';

export const MainLayout = () => {
	return (
		<Layout flex={1} direction='row' className='main-layout'>
			{store.model.isAuthorized && <SideBar />}
			<Layout flex={1} direction='column'>
				{store.model.isAuthorized && <Header />}
				<Layout flex={1} direction='column' className='main-layout__content'>
					<Outlet />
				</Layout>
			</Layout>
		</Layout>
	);
};


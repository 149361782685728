import { Loader } from '@consta/uikit/Loader';
import { useEffect } from 'react';
import { observer } from 'mobx-react';
import store from '@appStore';
import { createRoutesFromElements, Route, RouterProvider } from 'react-router';
import { MainLayout } from './layouts/main-layout/MainLayout';
import { Responses503 } from '@consta/uikit/Responses503';
import { RequireAuth } from './hocs/RequireAuth';
import { RequireAdmin } from './hocs/RequireAdmin';
import { createBrowserRouter } from 'react-router-dom';
import { ErrorFallback } from '@widgets/error-fallback';
import { PageNotFound } from '@widgets/page-not-found';
import { TestPage } from '@pages/test/TestPage';

/** Иницилизация стора приложения и стартовый роутинг страниц
 * Если в роутинге у path присутствует "route/*", то у страницы есть вложенный относительный роутинг
 * Все импорты из рутовых страниц сделаны в виде export default - это требование лези лоад
 */

export const AppRouter = observer(() => {
	useEffect(() => {
		store.init();
	}, []);
	if (store.model.error && !store.model.error.code) return <Responses503 actions={<div></div>} />;
	if (!store.model.isInitialized) return <Loader />;

	const router = createBrowserRouter(
		createRoutesFromElements(
			<>
				<Route element={<MainLayout />} ErrorBoundary={ErrorFallback}>
					<Route path='auth/*' lazy={() => import('@pages/auth')} />
					<Route element={<RequireAuth />}>
						<Route index lazy={() => import('@pages/main')} />
						<Route path='profile' lazy={() => import('@pages/profile')} />
						<Route path='admin' element={<RequireAdmin />}>
							<Route index path='*' lazy={() => import('@pages/admin')} />
						</Route>
					</Route>
					<Route path='test' element={<TestPage />} />
					<Route path='*' element={<PageNotFound />} />
				</Route>
			</>
		)
	);
	store.router = router;
	return <RouterProvider router={router} />;
});

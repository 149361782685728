import { Responses500 } from '@consta/uikit/Responses500';
import { Text } from '@consta/uikit/Text';
import { ToRootButton } from '@features/to-root-button';
import { useRouteError } from 'react-router';
import './errorFallback.css';

export const ErrorFallback = (props) => {
	let error = useRouteError();

	return (
		<Responses500
			className='error-fallback'
			actions={
				<div className='actions'>
					<Text truncate view='alert'>
						{error.message}
					</Text>

					<ToRootButton />
				</div>
			}
		/>
	);
};

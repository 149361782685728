import { observable } from 'mobx';
import store from '@appStore';
import { t } from 'i18next';

export class HeaderStore {
	@observable isUserContextOpen = false;

	goTo = (path) => () => store.router.navigate(path, { state: { from: store.currentPath } });
	openUserContext = () => (this.isUserContextOpen = true);
	closeUserContext = () => (this.isUserContextOpen = false);
	logout = () => store.model.logout();
	onUserContextSelect = (item) => {
		this.closeUserContext();
		item.onClick();
	};

	userContextItems = [
		{
			label: t('header.profile'),
			onClick: this.goTo('/profile'),
		},
		store.isAdmin && {
			label: t('header.admin'),
			onClick: this.goTo('/admin'),
		},
		{
			label: t('header.logout'),
			accent: 'alert',
			onClick: this.logout,
		},
	].filter(Boolean);
}


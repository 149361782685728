import React from 'react';
import { Button } from '@consta/uikit/Button';
import { AnimateIconSwitcherProvider } from '@consta/icons/AnimateIconSwitcherProvider';
import { withAnimateSwitcherHOC } from '@consta/icons/withAnimateSwitcherHOC';
import { IconArrowPrevious } from '@consta/icons/IconArrowPrevious';
import { IconArrowNext } from '@consta/icons/IconArrowNext';
import './arrowSwitcher.css';
import { classnames } from '@bem-react/classnames';

const MyAnimateIcon = withAnimateSwitcherHOC({
	startIcon: IconArrowPrevious,
	endIcon: IconArrowNext,
});

export const ArrowSwitcher = ({ onClick, className, active }) => {
	className = classnames('ArrowSwitcher', className);
	return (
		<AnimateIconSwitcherProvider active={active}>
			<Button view='ghost' size='s' onClick={onClick} iconLeft={MyAnimateIcon} onlyIcon className={className} />
		</AnimateIconSwitcherProvider>
	);
};

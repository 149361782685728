// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ThemeSwitcher {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--space-2xs)
}`, "",{"version":3,"sources":["webpack://./client/4-features/theme-switcher/ui/themeSwitcher.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB;AACJ","sourcesContent":[".ThemeSwitcher {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    gap: var(--space-2xs)\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LogoLabel {
    display: flex;
    align-items: flex-end;

    gap: var(--space-2xs);

    .text {
        --icon-size: unset;
    }
}`, "",{"version":3,"sources":["webpack://./client/4-features/logo-label/logoLabel.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,qBAAqB;;IAErB,qBAAqB;;IAErB;QACI,kBAAkB;IACtB;AACJ","sourcesContent":[".LogoLabel {\n    display: flex;\n    align-items: flex-end;\n\n    gap: var(--space-2xs);\n\n    .text {\n        --icon-size: unset;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

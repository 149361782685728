import { Navigate, Outlet } from 'react-router';
import store from '@appStore';

export const RequireAdmin = () => {
	if (!store.isAdmin) {
		return <Navigate to='/' />;
	}

	return <Outlet />;
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-fallback {
    .actions {
        display: flex;
        flex-direction: column;
        gap: var(--space-xl);
        align-items: center;
        max-width: 500px;
    }
}`, "",{"version":3,"sources":["webpack://./client/3-widgets/error-fallback/errorFallback.css"],"names":[],"mappings":"AAAA;IACI;QACI,aAAa;QACb,sBAAsB;QACtB,oBAAoB;QACpB,mBAAmB;QACnB,gBAAgB;IACpB;AACJ","sourcesContent":[".error-fallback {\n    .actions {\n        display: flex;\n        flex-direction: column;\n        gap: var(--space-xl);\n        align-items: center;\n        max-width: 500px;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

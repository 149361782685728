import { Switch } from '@consta/uikit/Switch';
import { observer } from 'mobx-react';
import { ThemeToggler } from '@consta/uikit/ThemeToggler';
import { Text } from '@consta/uikit/Text';
import store from '@appStore';
import { IconSun } from '@consta/icons/IconSun';
import { IconMoon } from '@consta/icons/IconMoon';
import './themeSwitcher.css';
import { useTranslation } from 'react-i18next';

const getItemIcon = (item) => {
	if (item === 'default') {
		return IconMoon;
	}
	if (item === 'dark') {
		return IconSun;
	}
};

export const ThemeSwitcher = observer(({ noLabel }) => {
	const { t } = useTranslation('components', { keyPrefix: 'ThemeSwitcher' });
	const onChange = (value) => {
		store.local.theme = value;
	};
	const currentTheme = store.local.theme;

	return (
		<div className='ThemeSwitcher'>
			<ThemeToggler items={['default', 'dark']} getItemLabel={(item) => item} getItemIcon={getItemIcon} value={store.local.theme} onChange={onChange} />
			{!noLabel && <Text size='s'>{t('select')}</Text>}
		</div>
	);
});

export const ThemeSwitcherWithLabel = observer(() => {
	const isDefaultTheme = store.local.theme === 'default';
	const { t } = useTranslation('components', { keyPrefix: 'ThemeSwitcher' });
	return <Switch label={t('darkTheme')} onChange={store.switchTheme} checked={!isDefaultTheme} />;
});

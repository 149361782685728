import { ModelStore } from '@smartplatform/model-store';
import { LocalStore } from '@smartplatform/local-store';
import { observable } from 'mobx';
import { Rest } from '@smartplatform/model-store';
import { localStoreConfig } from '@app/config/localStorage';
import { THEMES } from '@app/config/theme';

class AppStore {
	@observable local; // Локальное хранилище
	model; // Хранилище моделей
	route; // Хранилище маршрута браузера
	api; // API обмена с сервером
	admin; // Хранилище @admin
	ui; // ui
	socket; // вебсокет для общения с сервером
	config; // Конфигурация клиента
	error;
	@observable router; // объект реакт роутера

	@observable notifications = [];

	constructor() {
		this.local = new LocalStore();
		this.local.extend(localStoreConfig);
		this.rest = new Rest();
		this.model = new ModelStore({
			transport: this.rest,
			cache: false,
			autoConnect: false,
			onLogin: this.onLogin,
		});
	}

	switchTheme = () => {
		this.local.theme = this.local.theme === 'default' ? 'dark' : 'default';
	};

	get currentPath() {
		return this.router.state.location.pathname;
	}

	get selectedThemePreset() {
		const obj = {
			default: THEMES.DEFAULT,
			dark: THEMES.DARK,
		};

		return obj[this.local.theme] || THEMES.DEFAULT;
	}

	/**
	 * Вызывается при успешной авторизации и переподключает вебсокет
	 * @param user
	 */
	onLogin = (user) => {};

	get isAdmin() {
		return this.model.isAuthorized && this.model.roles.map((i) => i.name).includes('admin');
	}

	get isRegistertrationAllowed() {
		return this.model.config.enableRegistration;
	}

	/**
	 * Инициализация стора
	 * @returns {Promise<void>}
	 */
	init = async () => {
		try {
			await this.model.connect();
		} catch (e) {
			this.error = e;
			console.error(e);
		}
	};
}

window.APP_STORE = new AppStore();
export default window.APP_STORE;


import folderOpen from './assets/folderOpen.svg';
import notebook from './assets/notebook.svg';
import chartLineUp from './assets/chartLineUp.svg';
import solarPanel from './assets/solarPanel.svg';
import sliderHorizontal from './assets/sliderHorizontal.svg';

export const SIDEBAR_MENU = [
	{ icon: folderOpen, label: 'Обзор', path: '/', active: true },
	{ icon: chartLineUp, label: 'Анализ', path: '/page2' },
	{ icon: solarPanel, label: 'Энергобаланс', path: '/page3' },
	{ icon: notebook, label: 'Отчеты', path: '/page4' },
	{ icon: sliderHorizontal, label: 'Настройки', path: '/page6' },
];

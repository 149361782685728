// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    position: relative;

    &>:not(.SnackBar) {
        width: 100vw;
        height: 100vh;
        display: flex;
        background-color: var(--color-bg-secondary);
        color: var(--color-typo-primary);

        .Loader {
            margin: 0 auto;
            align-self: center;
        }



        a {
            text-decoration: none;

            &:active {
                color: inherit;
            }

            &.active {
                color: var(--color-typo-brand) !important;
            }

            &:visited {
                color: inherit;
            }

            &:hover {
                color: var(--color-typo-brand)
            }


        }
    }

    .SnackBar {
        position: absolute;
        right: 20px;
        top: 70px;
    }

    .Responses {
        height: 100%;
    }

    .Informer {
        height: max-content;
    }

}

.Modal-Window {
    padding: var(--space-2xl);
    background-color: var(--color-bg-secondary) !important;
}`, "",{"version":3,"sources":["webpack://./client/1-app/styles/app.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,aAAa;IACb,kBAAkB;;IAElB;QACI,YAAY;QACZ,aAAa;QACb,aAAa;QACb,2CAA2C;QAC3C,gCAAgC;;QAEhC;YACI,cAAc;YACd,kBAAkB;QACtB;;;;QAIA;YACI,qBAAqB;;YAErB;gBACI,cAAc;YAClB;;YAEA;gBACI,yCAAyC;YAC7C;;YAEA;gBACI,cAAc;YAClB;;YAEA;gBACI;YACJ;;;QAGJ;IACJ;;IAEA;QACI,kBAAkB;QAClB,WAAW;QACX,SAAS;IACb;;IAEA;QACI,YAAY;IAChB;;IAEA;QACI,mBAAmB;IACvB;;AAEJ;;AAEA;IACI,yBAAyB;IACzB,sDAAsD;AAC1D","sourcesContent":["#root {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    width: 100vw;\n    height: 100vh;\n    position: relative;\n\n    &>:not(.SnackBar) {\n        width: 100vw;\n        height: 100vh;\n        display: flex;\n        background-color: var(--color-bg-secondary);\n        color: var(--color-typo-primary);\n\n        .Loader {\n            margin: 0 auto;\n            align-self: center;\n        }\n\n\n\n        a {\n            text-decoration: none;\n\n            &:active {\n                color: inherit;\n            }\n\n            &.active {\n                color: var(--color-typo-brand) !important;\n            }\n\n            &:visited {\n                color: inherit;\n            }\n\n            &:hover {\n                color: var(--color-typo-brand)\n            }\n\n\n        }\n    }\n\n    .SnackBar {\n        position: absolute;\n        right: 20px;\n        top: 70px;\n    }\n\n    .Responses {\n        height: 100%;\n    }\n\n    .Informer {\n        height: max-content;\n    }\n\n}\n\n.Modal-Window {\n    padding: var(--space-2xl);\n    background-color: var(--color-bg-secondary) !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import en from './en';
import ru from './ru';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
	fallbackLng: 'ru',
	debug: true,
	resources: { en, ru },
	interpolation: {
		escapeValue: false, // not needed for react as it escapes by default
	},
});

i18n.addResourceBundle('ru', 'translation', ru, true, true);
i18n.addResourceBundle('en', 'translation', en, true, true);

export default i18n;

export const t = (key, options) => {
	return i18n.t(key, options);
};

window.i18n = i18n;

